import * as THREE from "three";
import { Vector2, Vector3 } from "three";

import Stats from "three/examples/jsm/libs/stats.module.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
// import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";

import { DisplaySection, UIState } from "./function/UIController";
import { playScrollAnimations } from "./function/text";

const stats = new Stats();

const clock = new THREE.Clock();
const STEPS_PER_FRAME = 5;

// Canvas
const canvas = document.querySelector("canvas.webgl");

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

let scrollY = window.scrollY;
let currentSection = 0;

let renderer, scene, camera;

var timeout;

const loadingManager = new THREE.LoadingManager();

const progressContainer = document.querySelector("#progress-container");
const progressBar = document.querySelector("#progress-bar");
const progressBarLoader = document.querySelector(".progress-bar-loading");
const progressBarText = document.querySelector(".progress-bar-text");

const subtitleContainer = document.querySelector("#subtitle-container");

const subtitleText = document.querySelector("#subtitle-text");
const subtitleText0 = document.querySelector("#subtitle-text0");
const subtitleText1 = document.querySelector("#subtitle-text1");
const subtitleText2 = document.querySelector("#subtitle-text2");
const subtitleText3 = document.querySelector("#subtitle-text3");
const subtitleText4 = document.querySelector("#subtitle-text4");
const subtitleText5 = document.querySelector("#subtitle-text5");
const subtitleText6 = document.querySelector("#subtitle-text6");
const subtitleText7 = document.querySelector("#subtitle-text7");
const subtitleText8 = document.querySelector("#subtitle-text8");
const subtitleText9 = document.querySelector("#subtitle-text9");

const rangeContainer = document.querySelector("#range-container");
const myRange = document.querySelector("#myRange");

loadingManager.onProgress = function (url, loaded, total) {
  var r = Math.random();
  var countNum = (loaded / total) * 35;
  var progress = Math.min(Math.max(parseFloat(countNum), 0), 35);

  progressBarLoader.style.width = countNum + "rem";
};

var onLoadDone = false;

loadingManager.onLoad = function () {
  progressBar.classList.add("progress-bar-done");
  progressBarText.textContent = "EXPLORE!";

  progressBar.addEventListener("click", () => {
    progressContainer.classList.add("hide");
    rangeContainer.classList.remove("hide");
    subtitleContainer.classList.remove("hide");

    onLoadDone = true;

    subtitleText.classList.remove("hide");
    setTimeout(function () {
      subtitleText.classList.add("hide");
      subtitleText0.classList.remove("hide");
    }, 6000);
    // subtitleText.classList.add("hide");
  });
};

// loadingManager.onError = function () {
//   console.error(`problem : ${url}`);
// };

var artworkArray = [
  "artwork_01",
  "artwork_02",
  "artwork_03",
  "artwork_08",
  "artwork_09",
  "artwork_10",
];

var artWebArray = ["artwork_01", "artwork_03", "artwork_09", "artwork_10"];

const loader = new GLTFLoader(loadingManager);
// const fbxLoader = new FBXLoader(loadingManager);

const camPos = [-1.9, 2.3, 0.7];

const pointer = new Vector2();
const rayCaster = new THREE.Raycaster();

const colliders = new THREE.Group();

let currentObject = null;
let mixer;

var videoArray = [
  document.getElementById("vid_1"),
  document.getElementById("vid_2"),
  document.getElementById("vid_3"),
  document.getElementById("vid_4"),
  document.getElementById("vid_5"),
  document.getElementById("vid_6"),
  document.getElementById("vid_7"),
  document.getElementById("vid_8"),
  document.getElementById("vid_9"),
  document.getElementById("vid_10"),
];

var videoTextures = [];
var videoMaterial = [];

const color1 = new THREE.Color("rgb(200,200,200)");
const color2 = new THREE.Color("rgb(30,30,30)");

for (var i = 0; i < videoArray.length; i++) {
  videoArray[i].pause();
  videoTextures[i] = new THREE.VideoTexture(videoArray[i]);
  videoTextures[i].minFilter = THREE.LinearFilter;
  videoTextures[i].magFilter = THREE.LinearFilter;
  videoTextures[i].format = THREE.RGBAFormat;

  videoTextures[i].needsUpdate = true;
  videoMaterial[i] = new THREE.MeshBasicMaterial({
    map: videoTextures[i],
    color: color2,
  });
}

var diagramSlider = document.querySelector(".slider");

function InitializeSceneAssets(callback) {
  callback ? callback() : "";
}

InitializeSceneAssets(async () => {
  await init();
  animate();
});

async function init() {
  window.scrollTo({ top: 0, behavior: "smooth" });
  //scene
  scene = new THREE.Scene();
  scene.background = new THREE.Color(0x88ccee);
  scene.fog = new THREE.Fog(0x88ccee, 0, 50);

  //camera
  camera = new THREE.PerspectiveCamera(
    50,
    window.innerWidth / window.innerHeight,
    0.01,
    1000
  );

  camera.position.set(camPos[0], camPos[1], camPos[2]);
  camera.rotateY(0);

  //light

  const amblight = new THREE.AmbientLight(0x404040, 2.4); // soft white light
  scene.add(amblight);

  //stats

  stats.domElement.style.position = "absolute";
  stats.domElement.style.top = "0px";
  // document.body.appendChild(stats.domElement);

  //gltf loader

  loader.load("room_scene_v11.glb", (gltf) => {
    colliders.add(gltf.scene);
    colliders.castShadow = false;
    colliders.receiveShadow = false;

    var i = 0;

    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = false;
        child.receiveShadow = false;
        if (child.name.includes("artwork")) {
          child.material = videoMaterial[i];
          // console.log(child);
          child.material.needsUpdate = true;
          i++;
          // console.log(child);
        }
        if (child.material.map) {
          child.material.map.anisotropy = 4;
        }
      }
    });

    // scene.add(colliders);
  });

  loader.load("avatar_v4.glb", (gltf) => {
    colliders.add(gltf.scene);
    // gltf.scene.scale.set(0.01, 0.01, 0.01);

    mixer = new THREE.AnimationMixer(gltf.scene);
    gltf.animations.forEach((clip) => {
      mixer.clipAction(clip).play();
    });

    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        child.frustumCulled = false;
        if (child.material.map) {
          child.material.map.anisotropy = 4;
        }
      }
    });

    scene.add(colliders);
  });

  renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true,
  });

  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  window.addEventListener("resize", onWindowResize);

  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.toneMapping = THREE.ACESFilmicToneMapping;

  myRange.addEventListener("input", () => {
    position = myRange.value * -1;
  });

  // subtitleText.addEventListener("change", () => {
  //   if (timeout) {
  //     clearTimeout(timeout);
  //   }
  //   subtitleText.classList.add("hide");
  //   timeout = setTimeout(function () {
  //     subtitleText.classList.remove("hide");
  //   }, 1);
  // });

  window.addEventListener("touchmove", onMouseWheel, false);
  window.addEventListener("scroll", onMouseWheel, false);
  window.addEventListener("mousewheel", onMouseWheel);
  window.addEventListener("wheel", onMouseWheel);
  // window.addEventListener("resize", onWindowResize);
  window.addEventListener("mousemove", onMouseMove, false);
  window.addEventListener("click", onMouseClick, false);

  if (!navigator.platform.match(/iPhone|iPod|iPad/)) {
    diagramSlider.addEventListener("touched", iosPolyfill, { passive: true });
  }
}

// var diagramSlider = document.querySelector(".slider");

function iosPolyfill(e) {
  var val =
      (e.pageX - diagramSlider.getBoundingClientRect().left) /
      (diagramSlider.getBoundingClientRect().right -
        diagramSlider.getBoundingClientRect().left),
    max = diagramSlider.getAttribute("max"),
    segment = 1 / (max - 1),
    segmentArr = [];

  max++;

  for (var i = 0; i < max; i++) {
    segmentArr.push(segment * i);
  }

  var segCopy = segmentArr.slice(),
    ind = segmentArr.sort((a, (b) => Math.abs(val - a) - Math.abs(val - b)))[0];

  diagramSlider.value = segCopy.indexOf(ind) + 1;
}

function handleSwipeUp() {
  if (touchendY < touchstartY && !UIState) {
    eventY -= 0.9;
  } else if (touchendY > touchstartY && !UIState) {
    eventY += 0.9;
  }
}

let eventY = 0;
let position = 0;

function onMouseClick(event) {
  // console.log(inputValue);

  // if (!currentObject) return;
  // DisplaySection(currentObject);

  console.log("CLICK");

  rayCaster.setFromCamera(pointer, camera);
  const intersects = rayCaster.intersectObjects(scene.children);

  if (intersects.length > 0) {
    for (var i = 0; i < artWebArray.length; i++) {
      if (intersects[0].object.name.includes(artWebArray[i])) {
        DisplaySection(intersects[0].object);
      } else {
        currentObject = null;
      }
    }

    if (intersects[0].object.name.includes("link_01")) {
      window.open("https://www.linkedin.com/in/andrewchang0106/");
    } else if (intersects[0].object.name.includes("link_02")) {
      window.open("https://www.instagram.com/chia_yang.tw/");
    } else if (intersects[0].object.name.includes("artwork_02")) {
      window.open("https://www.youtube.com/watch?v=9ybhAKghemE");
    } else if (intersects[0].object.name.includes("artwork_08")) {
      window.open("https://www.artstation.com/artwork/EaPb9N");
    } else if (!currentObject) return;
  }
}

function onMouseWheel(event) {
  if (onLoadDone) {
    if (!UIState) {
      // eventY -= 0.9;
      eventY = event.deltaY * 0.007;
      // eventY = window.pageYOffset * 0.007;
    }
    return false;
  }
}

function onWindowResize() {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
}

function onMouseMove(event) {
  pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
  pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
}

function animate() {
  requestAnimationFrame(animate);
  if (UIState) {
    currentObject = null;
    // console.log("true");
    return;
  } else {
    renderer.render(scene, camera);
  }

  position += eventY;
  eventY *= 0.96;

  position = Math.min(Math.max(parseFloat(position), -450), 0);

  myRange.value = position * -1;
  // console.log(position);

  playScrollAnimations(position, camera, camPos);

  // work

  const delta = clock.getDelta();

  if (mixer) mixer.update(delta);

  // console.log(position);

  // stats.update();

  var fanobject = scene.getObjectByName("fan002");
  if (fanobject instanceof THREE.Mesh) {
    fanobject.rotation.y = -0.5;
    fanobject.rotation.z += 0.1;
  }

  //ray cast

  rayCaster.setFromCamera(pointer, camera);
  const intersects = rayCaster.intersectObjects(scene.children);

  var link1;

  if (intersects.length > 0) {
    if (intersects[0].object.name.includes("artwork")) {
      currentObject = intersects[0].object;

      // DisplaySection(currentObject);

      var artName = currentObject.name;
      var artNumber = artName.substr(8); // "001"
      // var artNumber = artName.split("_")[1];
      // console.log(artNumber);
      if (videoArray[artNumber - 1].paused) {
        videoArray[artNumber - 1].play();
        videoMaterial[artNumber - 1].color.set(color1);
      }
      // document.body.style.cursor = "pointer";
    } else {
      for (var i = 0; i < videoArray.length; i++) {
        videoArray[i].pause();
        videoMaterial[i].color.set(color2);
      }
      document.body.style.cursor = "default";
      // currentObject = null;
    }

    for (var i = 0; i < artworkArray.length; i++) {
      if (intersects[0].object.name.includes(artworkArray[i])) {
        document.body.style.cursor = "pointer";
      }
    }

    let linkObject;

    if (
      intersects[0].object.name.includes("link_01") ||
      intersects[0].object.name.includes("link_02")
    ) {
      document.body.style.cursor = "pointer";
      linkObject = intersects[0].object;
      linkObject.scale.set(1.5, 1.5, 1.5);
    } else {
      scene.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
          if (child.name.includes("link")) {
            child.scale.set(1, 1, 1);
          }
        }
      });
    }

    // scene.traverse(function (child) {
    //   if (child instanceof THREE.Mesh) {
    //     if (child.name.includes("art_work")) {
    //     }
    //     // child.material.map = textures[i];
    //     // child.material.needsUpdate = true;
    //   }
    // });
  }
}

function render() {
  renderer.render(scene, camera);
}
