import * as THREE from "three";
import j_Work_Info from "../workData/works-information.json";

// const m_WorkSection = document.querySelector("#section");
const m_SectorTitle = document.querySelector("#sector-title");

// const m_CloseSection = document.querySelector(".close-btn");
const m_CloseSections = document.querySelectorAll(".close-btn");

let currentSceneObject = null,
  UIState = false;

// m_WorkSection.classList.add("hide");

let timeout_click, timeout_unclick;

function DisplaySection(object = {}, name = "") {
  currentSceneObject = object;
  document.body.style.cursor = "default";

  if (j_Work_Info[currentSceneObject.name].nameElement !== undefined) {
    UIState = true;
    const current_section = document.querySelector(
      j_Work_Info[currentSceneObject.name].nameElement
    );
    current_section.classList.remove("hide");

    m_CloseSections.forEach((btn) => {
      btn.addEventListener("click", () => {
        current_section.classList.add("hide");
        UIState = false;
      });
    });
  } else return;

  //j_District_Info[work-name].name
  // m_SectorTitle.innerText = j_Work_Info[currentSceneObject.name].name;
}

export { DisplaySection, UIState };
