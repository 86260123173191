{
  "artwork_01": {
    "name": "work1",
    "nameElement": "#section_1"
  },

  "artwork_03": {
    "name": "work2",
    "nameElement": "#section_2"
  },
  "artwork_09": {
    "name": "work3",
    "nameElement": "#section_3"
  },
  "artwork_10": {
    "name": "work4",
    "nameElement": "#section_4"
  }
}
