let m_scrollPercent, m_camera;
let m_carmeraPos = [];

var timeout;

// var subtitleText = document.getElementById("subtitle-text");

const subtitleText = document.querySelector("#subtitle-text");
const subtitleText0 = document.querySelector("#subtitle-text0");
const subtitleText1 = document.querySelector("#subtitle-text1");
const subtitleText2 = document.querySelector("#subtitle-text2");
const subtitleText3 = document.querySelector("#subtitle-text3");
const subtitleText4 = document.querySelector("#subtitle-text4");
const subtitleText5 = document.querySelector("#subtitle-text5");
const subtitleText6 = document.querySelector("#subtitle-text6");
const subtitleText7 = document.querySelector("#subtitle-text7");

function lerp(x: number, y: number, a: number): number {
  return (1 - a) * x + a * y;
}

function scalePercent(start: number, end: number) {
  return (m_scrollPercent - start) / (end - start);
}

function textChange() {
  subtitleText!.classList.add("hide");
  timeout = setTimeout(function () {
    subtitleText!.classList.remove("hide");
  }, 1);
}

const animationScripts: { start: number; end: number; func: () => void }[] = [];

animationScripts.push({
  start: -40,
  end: 0,
  func: () => {
    m_camera.position.x = lerp(-3.3, m_carmeraPos[0], scalePercent(-40, 0));
    m_camera.position.y = lerp(0.6, m_carmeraPos[1], scalePercent(-40, 0));
    m_camera.position.z = lerp(1, m_carmeraPos[2], scalePercent(-40, 0));
  },
});

animationScripts.push({
  start: -180,
  end: -60,
  func: () => {
    m_camera.position.x = lerp(0.8, -3.3, scalePercent(-180, -60));
    // m_camera.position.y = lerp(0.6, 0.6, scalePercent(-180, -60));
  },
});

animationScripts.push({
  start: -250,
  end: -180,
  func: () => {
    m_camera.position.x = lerp(2, 0.8, scalePercent(-250, -180));
    m_camera.position.y = lerp(1.8, 0.6, scalePercent(-250, -180));
  },
});

animationScripts.push({
  start: -300,
  end: -250,
  func: () => {
    // m_camera.position.x = lerp(2, 0.8, scalePercent(-300, -250));
    m_camera.position.y = lerp(0.6, 1.8, scalePercent(-300, -250));
  },
});

animationScripts.push({
  start: -330,
  end: -300,
  func: () => {
    m_camera.position.x = lerp(3, 2, scalePercent(-330, -300));
    // m_camera.position.y = lerp(1.8, 0.6, scalePercent(-360, -300));
  },
});

animationScripts.push({
  start: -380,
  end: -330,
  func: () => {
    m_camera.position.x = lerp(4.42, 3, scalePercent(-380, -330));
    m_camera.position.y = lerp(2, 0.6, scalePercent(-380, -330));
    m_camera.position.z = lerp(0.5, 1, scalePercent(-380, -330));
  },
});

animationScripts.push({
  start: -450,
  end: -400,
  func: () => {
    m_camera.position.x = lerp(0, 4.42, scalePercent(-450, -400));
    m_camera.position.y = lerp(1.5, 2, scalePercent(-450, -400));
    m_camera.position.z = lerp(5.5, 0.5, scalePercent(-450, -400));
  },
});

//animated subtitle

var textRun = false;
var textRun2 = false;

animationScripts.push({
  start: -10,
  end: -5,
  func: () => {
    // subtitleText!.classList.add("hide");
    subtitleText0!.classList.add("hide");
    subtitleText1!.classList.add("hide");
    subtitleText2!.classList.add("hide");
    subtitleText4!.classList.add("hide");
    subtitleText5!.classList.add("hide");
    subtitleText3!.classList.add("hide");
    subtitleText!.classList.remove("hide");
  },
});

animationScripts.push({
  start: -80,
  end: -30,
  func: () => {
    subtitleText!.classList.add("hide");
    subtitleText0!.classList.add("hide");
    subtitleText3!.classList.add("hide");

    if (!textRun) {
      subtitleText1!.classList.remove("hide");
    }
    if (textRun2) {
      subtitleText2!.classList.remove("hide");
    }
    timeout = setTimeout(function () {
      textRun2 = true;
      subtitleText1!.classList.add("hide");
      // subtitleText2!.classList.remove("hide");
      textRun = true;
    }, 6000);
  },
});

animationScripts.push({
  start: -120,
  end: -90,
  func: () => {
    subtitleText!.classList.add("hide");
    subtitleText0!.classList.add("hide");
    subtitleText1!.classList.add("hide");
    subtitleText2!.classList.add("hide");
    subtitleText4!.classList.add("hide");
    subtitleText5!.classList.add("hide");
    subtitleText3!.classList.remove("hide");
    subtitleText6!.classList.add("hide");
    subtitleText7!.classList.add("hide");
  },
});

animationScripts.push({
  start: -180,
  end: -120,
  func: () => {
    subtitleText!.classList.add("hide");
    subtitleText0!.classList.add("hide");
    // subtitleText1!.classList.add("hide");
    // subtitleText2!.classList.add("hide");
    subtitleText5!.classList.add("hide");
    // subtitleText3!.classList.add("hide");
    subtitleText4!.classList.remove("hide");
  },
});

animationScripts.push({
  start: -310,
  end: -200,
  func: () => {
    subtitleText!.classList.add("hide");
    subtitleText0!.classList.add("hide");
    subtitleText5!.classList.add("hide");
    subtitleText3!.classList.add("hide");
    subtitleText4!.classList.add("hide");
    subtitleText6!.classList.add("hide");
    subtitleText7!.classList.add("hide");
  },
});

var textRun3 = false;
var textRun4 = false;

animationScripts.push({
  start: -330,
  end: -290,
  func: () => {
    subtitleText6!.classList.add("hide");
    subtitleText7!.classList.add("hide");
    subtitleText!.classList.add("hide");
    subtitleText0!.classList.add("hide");
    // subtitleText5!.classList.add("hide");
    subtitleText3!.classList.add("hide");
    subtitleText4!.classList.add("hide");

    if (!textRun3) {
      subtitleText5!.classList.remove("hide");
    }
    if (textRun4) {
      subtitleText6!.classList.remove("hide");
    }
    timeout = setTimeout(function () {
      textRun4 = true;
      subtitleText1!.classList.add("hide");
      textRun3 = true;
    }, 6000);
  },
});

animationScripts.push({
  start: -400,
  end: -350,
  func: () => {
    subtitleText!.classList.add("hide");
    subtitleText0!.classList.add("hide");
    subtitleText1!.classList.add("hide");
    subtitleText2!.classList.add("hide");
    subtitleText3!.classList.add("hide");
    subtitleText4!.classList.add("hide");
    subtitleText5!.classList.add("hide");
    subtitleText6!.classList.add("hide");
    subtitleText7!.classList.add("hide");
  },
});

animationScripts.push({
  start: -450,
  end: -400,
  func: () => {
    subtitleText!.classList.add("hide");
    subtitleText0!.classList.add("hide");
    subtitleText1!.classList.add("hide");
    subtitleText2!.classList.add("hide");
    subtitleText3!.classList.add("hide");
    subtitleText4!.classList.add("hide");
    subtitleText5!.classList.add("hide");
    subtitleText6!.classList.add("hide");
    subtitleText7!.classList.remove("hide");
  },
});
function playScrollAnimations(scrollPercent, camera, carmeraPos) {
  m_scrollPercent = scrollPercent;
  m_camera = camera;
  m_carmeraPos = carmeraPos;

  animationScripts.forEach((a) => {
    if (m_scrollPercent >= a.start && m_scrollPercent < a.end) {
      a.func();
    }
  });
}

export { playScrollAnimations };
